@forward "boilerplate";
@forward "customization";
@forward "HomePage";
@forward "Quiz";
@forward "categoryPage";
@forward "loader";


.examCopyModal {
    .ant-modal-content {
        border-radius: 20px;
    }

    .ant-modal-footer {
        display: none;
    }
}