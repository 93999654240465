.signin-inputs,
.submit,
textarea,
select {
  font-size: 16px;
  width: 100%;
  min-height: 48px;
  padding: 010px;
  border: 0;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.15);
}

.react-tel-input input {
  background: none;
}

.submit {
  text-align: center;

}

.signin-inputs::placeholder,
textarea::placeholder {
  color: #000205bd;
  opacity: 0.5;
  font-size: 1.5rem;
  font-weight: 600;
}

// .signin-inputs:focus,
// textarea:focus {
//   outline: none;
//   border: none;
//   box-shadow: 0 0 0 2px #2e30ff;
//   color: #2e30ff;
//   background-color: #eff6ff;

//   &::placeholder {
//     color: #2e30ff;
//   }
// }

label {
  font-size: 1.6rem;
  font-weight: 600;
  letter-spacing: 0.05rem;
  width: 100%;
}

.submit {
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  font-size: 1.7rem;
  font-weight: 700;
}

.submit:active {
  transform: scale(0.9);
}

.submit:disabled {
  border: 2px solid #4285f466;
  color: #4285f466;
}

.submit:disabled:active {
  transform: scale(1);
}


select:focus {
  outline: none;
  border: none;
  box-shadow: 0 0 0 2px #2e30ff;
  color: #2e30ff;
  background-color: #fff;
}

input[type="file"] {
  height: 3.4rem;
  padding: 0.4rem;
  font-size: 1.2rem;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.question-submit {
  width: 250px;
}

//!------ permissions checkbox ---------

.checkbox-wrapper {
  position: relative;
}

.checkbox-wrapper>svg {
  position: absolute;
  top: -130%;
  left: -170%;
  width: 110px;
  pointer-events: none;
}

.checkbox-wrapper * {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.checkbox-wrapper input[type="checkbox"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-tap-highlight-color: transparent;
  cursor: pointer;
  margin: 0;
}

.checkbox-wrapper input[type="checkbox"]:focus {
  outline: 0;
}

.checkbox-wrapper .cbx {
  width: 24px;
  height: 24px;
  top: calc(100px - 12px);
  left: calc(100px - 12px);
}

.checkbox-wrapper .cbx input {
  position: absolute;
  top: 0;
  left: 0;
  width: 24px;
  min-height: 24px;
  border: 2px solid #4285f4;
  background-color: rgba(66, 133, 244, 0.4);
  border-radius: 50%;
}

.checkbox-wrapper .cbx label {
  width: 24px;
  height: 24px;
  background: none;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  pointer-events: none;
}

.checkbox-wrapper .cbx svg {
  position: absolute;
  top: 5px;
  left: 4px;
  z-index: 1;
  pointer-events: none;
}

.checkbox-wrapper .cbx svg path {
  stroke: #fef2f2;
  stroke-width: 3;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-dasharray: 19;
  stroke-dashoffset: 19;
  -webkit-transition: stroke-dashoffset 0.3s ease;
  transition: stroke-dashoffset 0.3s ease;
  -webkit-transition-delay: 0.2s;
  transition-delay: 0.2s;
}

.checkbox-wrapper .cbx input:checked+label {
  -webkit-animation: splash-12 0.6s ease forwards;
  animation: splash-12 0.6s ease forwards;
}

.checkbox-wrapper .cbx input:checked+label+svg path {
  stroke-dashoffset: 0;
}

@-webkit-keyframes splash-12 {
  40% {
    background: #4285f4;
    -webkit-box-shadow:
      0 -18px 0 -8px #4285f4,
      16px -8px 0 -8px #4285f4,
      16px 8px 0 -8px #4285f4,
      0 18px 0 -8px #4285f4,
      -16px 8px 0 -8px #4285f4,
      -16px -8px 0 -8px #4285f4;
    box-shadow:
      0 -18px 0 -8px #4285f4,
      16px -8px 0 -8px #4285f4,
      16px 8px 0 -8px #4285f4,
      0 18px 0 -8px #4285f4,
      -16px 8px 0 -8px #4285f4,
      -16px -8px 0 -8px #4285f4;
  }

  100% {
    background: #4285f4;
    -webkit-box-shadow:
      0 -36px 0 -10px transparent,
      32px -16px 0 -10px transparent,
      32px 16px 0 -10px transparent,
      0 36px 0 -10px transparent,
      -32px 16px 0 -10px transparent,
      -32px -16px 0 -10px transparent;
    box-shadow:
      0 -36px 0 -10px transparent,
      32px -16px 0 -10px transparent,
      32px 16px 0 -10px transparent,
      0 36px 0 -10px transparent,
      -32px 16px 0 -10px transparent,
      -32px -16px 0 -10px transparent;
  }
}

@keyframes splash-12 {
  40% {
    background: #4285f4;
    -webkit-box-shadow:
      0 -18px 0 -8px #4285f4,
      16px -8px 0 -8px #4285f4,
      16px 8px 0 -8px #4285f4,
      0 18px 0 -8px #4285f4,
      -16px 8px 0 -8px #4285f4,
      -16px -8px 0 -8px #4285f4;
    box-shadow:
      0 -18px 0 -8px #4285f4,
      16px -8px 0 -8px #4285f4,
      16px 8px 0 -8px #4285f4,
      0 18px 0 -8px #4285f4,
      -16px 8px 0 -8px #4285f4,
      -16px -8px 0 -8px #4285f4;
  }

  100% {
    background: #4285f4;
    -webkit-box-shadow:
      0 -36px 0 -10px transparent,
      32px -16px 0 -10px transparent,
      32px 16px 0 -10px transparent,
      0 36px 0 -10px transparent,
      -32px 16px 0 -10px transparent,
      -32px -16px 0 -10px transparent;
    box-shadow:
      0 -36px 0 -10px transparent,
      32px -16px 0 -10px transparent,
      32px 16px 0 -10px transparent,
      0 36px 0 -10px transparent,
      -32px 16px 0 -10px transparent,
      -32px -16px 0 -10px transparent;
  }
}

// main button
.tp-btn-hover {
  position: relative;
  z-index: 9;
  overflow: hidden;
}

.tp-btn-hover b {
  position: absolute;
  width: 0px;
  height: 0px;
  background-color: #6c60fe;
  border-radius: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  z-index: -1;
}

.tp-btn-hover:hover b {
  width: 500px;
  height: 500px;
  display: inline-block;
}

.tp-btn-hover.alt-color b {
  background-color: #ff3c58;
}

.tp-btn-hover.alt-bg-orange b {
  background-color: #ff3221;
}

.tp-btn-hover.alt-black-color b {
  background-color: black;
}

.tp-btn-hover.alt-bg-white b {
  background-color: #fff;
}

.tp-btn-hover.alt-bg-red b {
  background-color: #c5002e !important;
}

.tp-btn {
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 700;
  background-color: #ff3c58 !important;
  color: #fff !important;
  padding: 18px 29px;
  border-radius: 8px;
  display: inline-block;
}

.tp-btn:hover {
  color: #fff;
}

.tp-btn:hover span svg:first-child,
.tp-btn:hover span i:first-child {
  opacity: 1;
  transform: translateZ(0);
}

.tp-btn:hover span svg:last-child,
.tp-btn:hover span i:last-child {
  opacity: 0;
  transform: translateX(30px) translateY(0);
}

.tp-btn i {
  margin-left: 15px;
}

.tp-btn span {
  display: inline-block;
  position: relative;
}

.tp-btn span svg,
.tp-btn span i {
  transition: transform 0.6s cubic-bezier(0.77, 0, 0.175, 1), opacity 0.6s cubic-bezier(0.77, 0, 0.175, 1);
}

.tp-btn span svg:first-child,
.tp-btn span i:first-child {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  transform: translateX(-20px) translateY(0);
}

.tp-btn span svg:last-child,
.tp-btn span i:last-child {
  opacity: 1;
  display: block;
  transform: translateY(0) translateY(0);
}

@media (max-width: 767px) {
  .tp-btn {
    font-size: 14px;
    padding: 15px 29px;
  }
}

// secondary button 
.tp-common-btn {
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 700;
  background-color: #6c60fe;
  color: #fff;
  padding: 18px 29px;
  border-radius: 8px;
  display: inline-block;
}

.tp-common-btn:hover {
  color: #fff;
}

.tp-common-btn:hover span svg:first-child,
.tp-common-btn:hover span svg:first-child {
  opacity: 1;
  transform: translateZ(0);
}

.tp-common-btn:hover span svg:last-child,
.tp-common-btn:hover span svg:last-child {
  opacity: 0;
  transform: translateX(30px) translateY(0);
}

.tp-common-btn svg {
  margin-left: 15px;
}

.tp-common-btn span {
  margin-left: 5px;
  display: inline-block;
  position: relative;
}

.tp-common-btn span svg,
.tp-common-btn span i {
  transition: transform 0.6s cubic-bezier(0.77, 0, 0.175, 1), opacity 0.6s cubic-bezier(0.77, 0, 0.175, 1);
}

.tp-common-btn span svg:first-child,
.tp-common-btn span i:first-child {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  transform: translateX(-20px) translateY(0);
}

.tp-common-btn span svg:last-child,
.tp-common-btn span i:last-child {
  opacity: 1;
  display: block;
  transform: translateY(0) translateY(0);
}

@media (max-width: 767px) {
  .tp-common-btn {
    font-size: 14px;
    padding: 15px 29px;
  }
}