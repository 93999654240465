@import url("https://fonts.googleapis.com/css2?family=Cairo:wght@400;600;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800;900&display=swap");

//fonts
$heading: "Arapey", serif;
$body: "Maitree", serif;
$menu: "Inter", sans-serif;
//colors

:root {
  $purple: #7664ff;
  $light-purple: #bdadfc;
  $dark-purple: #77298c;
  $pink: #f1ccfa;
  $blue: #0c145e;
  $light-gray: #eaebf5;
  $dark-gray: #b2b8e8;

  $rose: #f1468e;
  $light-rose: #df90bd;
  $bg: rgb(255, 201, 169);
  $white: #ffffff;
  $black: #000000;
}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Cairo", sans-serif;
}

html {
  font-size: 62.5%;
  scroll-behavior: smooth;
}

body {
  //background-color: #f3f4f6;
  //color:#7664ff ;
  font-size: 1.6rem;
  overflow-x: hidden;
}

a {
  text-decoration: none;
  color: inherit;
}

ul {
  list-style: none;
}

.width {
  width: min(90%, 1300px);
  margin-inline: auto;
}

.buttonS1 {
  text-align: center;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 700;
  background-color: #2e30ff;
  color: #fff;
  padding: 18px 29px;
  border-radius: 8px;
  display: inline-block;

  &:hover {
    position: relative;
    z-index: 9;
    overflow: hidden;
  }
}